var render = function render() {
  var _vm = this,
    _c = _vm._self._c
  return _c(
    "travio-center-container",
    { attrs: { "grid-width": "2/3" } },
    [
      _c("h2", { staticClass: "mb-3", staticStyle: { color: "#636363" } }, [
        _c("span", { staticClass: "text-muted" }, [
          _vm._v("Edit Location List"),
        ]),
      ]),
      _c(
        "vx-card",
        {
          directives: [
            {
              name: "show",
              rawName: "v-show",
              value: _vm.locationListName,
              expression: "locationListName",
            },
          ],
        },
        [
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-start mb-3" },
            [
              _c("h2", { staticStyle: { color: "#636363" } }, [
                _vm._v(_vm._s(_vm.locationListName)),
              ]),
              _c(
                "vs-button",
                {
                  staticClass: "ml-3",
                  attrs: { size: "small", type: "border", color: "success" },
                  on: { click: _vm.editListLocationName },
                },
                [_vm._v("Rename")]
              ),
            ],
            1
          ),
          _c(
            "vs-breadcrumb",
            { attrs: { separator: "chevron_right", items: _vm.menuItems } },
            _vm._l(_vm.menuItems, function (menuItem, index) {
              return _c(
                "li",
                { key: index },
                [
                  !menuItem.active
                    ? _c(
                        "router-link",
                        {
                          staticClass: "vs-breadcrumb--link",
                          attrs: { to: menuItem.url },
                        },
                        [_vm._v(_vm._s(menuItem.title))]
                      )
                    : _vm._e(),
                  menuItem.active
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "vs-breadcrumb--text vs-breadcrumb-text-primary",
                        },
                        [_vm._v(_vm._s(menuItem.title))]
                      )
                    : _vm._e(),
                  !menuItem.active
                    ? _c(
                        "span",
                        {
                          staticClass:
                            "separator notranslate vs-breadcrum--separator material-icons",
                          attrs: {
                            translate: "translate",
                            "aria-hidden": "true",
                          },
                        },
                        [_vm._v("\n          chevron_right\n        ")]
                      )
                    : _vm._e(),
                ],
                1
              )
            }),
            0
          ),
          _c(
            "div",
            { staticClass: "flex flex-wrap justify-start mt-3" },
            [
              !_vm.isLastLevel
                ? _c(
                    "vs-button",
                    {
                      staticClass: "sm:mb-0 mb-2",
                      on: { click: _vm.addLocation },
                    },
                    [_vm._v("Add Parent Location")]
                  )
                : _vm._e(),
              !_vm.isFirstLevel
                ? _c(
                    "vs-button",
                    {
                      staticClass: "ml-2 sm:mb-0 mb-2",
                      on: { click: _vm.addLocationByAutoComplete },
                    },
                    [_vm._v("Add Searchable Location")]
                  )
                : _vm._e(),
            ],
            1
          ),
          _c("ag-grid-vue", {
            ref: "agGridTable",
            staticClass: "ag-theme-material w-100 ag-grid-table",
            attrs: {
              components: _vm.cellRendererComponents,
              columnDefs: _vm.columnDefs,
              defaultColDef: _vm.defaultColDef,
              rowData: _vm.locationList,
              gridOptions: _vm.gridOptions,
              rowDragManaged: true,
              animateRows: true,
              rowSelection: "single",
              pagination: false,
              context: _vm.context,
              suppressPaginationPanel: true,
            },
            on: {
              "first-data-rendered": _vm.onFirstDataRendered,
              "grid-ready": _vm.onGridReady,
            },
          }),
        ],
        1
      ),
      _vm.showLocationEditor
        ? _c("location-editor", {
            attrs: {
              applicationId: _vm.applicationId,
              location: _vm.locationStateForEditor,
            },
            on: {
              onAddSuccess: _vm.onAddSuccess,
              onEditSuccess: _vm.onEditSuccess,
              onCancel: function ($event) {
                _vm.showLocationEditor = false
              },
            },
          })
        : _vm._e(),
      _vm.showLocationAutoComplete
        ? _c("location-editor-auto-complete", {
            attrs: {
              applicationId: _vm.applicationId,
              location: _vm.locationStateForEditor,
            },
            on: {
              onAddSuccess: _vm.onAddSuccessAutoComplete,
              onEditSuccess: _vm.onEditSuccessAutoComplete,
              onCancel: function ($event) {
                _vm.showLocationAutoComplete = false
              },
            },
          })
        : _vm._e(),
      _vm.showListNameEditor
        ? _c("location-list-name-editor", {
            attrs: {
              applicationId: _vm.applicationId,
              locationListId: _vm.locationListId,
              locationListName: _vm.locationListName,
            },
            on: {
              onEditSuccess: _vm.onEditListNameSuccess,
              onCancel: function ($event) {
                _vm.showListNameEditor = false
              },
            },
          })
        : _vm._e(),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }